
          @import "src/styles/variables/fonts.scss";
        
@import "reset.css";

*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: $primary-font;
    font-size: 16px;
}