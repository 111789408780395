.tw-slider .ant-slider-track {
    background-image: linear-gradient(
      to right,
      rgb(60, 104, 255),
      rgb(255, 202, 224),
      rgb(255, 212, 56),
      rgb(255, 139, 74),
      rgb(255, 53, 53),
      rgb(39, 153, 107)
    );
    height: 0.75rem;
    border-radius: 0.375rem;
  }
  
  .tw-slider .ant-slider-handle {
    border: none;
    width: 1.75rem;
    height: 1.75rem;
    margin-top: -0.5rem;
    background-color: rgb(60, 104, 255);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    transition: background-color 0.3s ease;
  }
  
  .tw-slider:hover .ant-slider-handle {
    background-color: rgb(39, 153, 107);
  }
  
  .tw-slider .ant-slider-handle::after {
    content: none;
  }
  
  .tw-slider .ant-slider-mark-text {
    color: #4B5563;
    font-size: 0.75rem;
    font-weight: 600;
    margin-top: 0.5rem;
  }
  
  .tw-slider .ant-slider-rail {
    height: 0.75rem;
    background-image: linear-gradient(
      to right,
      rgba(60, 104, 255, 0.2),
      rgba(255, 202, 224, 0.2),
      rgba(255, 212, 56, 0.2),
      rgba(255, 139, 74, 0.2),
      rgba(255, 53, 53, 0.2),
      rgba(39, 153, 107, 0.2)
    );
    border-radius: 0.375rem;
  }
  
  .tw-slider {
  width: 100%;
}

@media (min-width: 640px) {
  .tw-slider {
    width: auto;
  }
}
